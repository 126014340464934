@mixin xxxs {
  @media only screen and (max-width: 400px) {
    @content;
  }
}

@mixin xxs {
  @media only screen and (max-width: 450px) {
    @content;
  }
}

@mixin xs {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin sm {
  @media only screen and (max-width: 992px) {
    @content;
  }
}

@mixin md {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}

@mixin xlg {
  @media only screen and (max-width: 1600px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (max-width: 1920px) {
    @content;
  }
}
//Usage:
// 1. Import:
// @import "../../_styles/mixins";
// 2. Using:
// &:hover {
//     transition: 0.1s;
//     @include xl {
//       transition: 1s;
//     }
//   }

@mixin noUserSelect {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
