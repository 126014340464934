@import "../../styles/mixins";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  height: 100dvh;
  padding: 20dvh 25vw;

  @include xs {
    margin: 0;
    padding: 4dvh 5vw;
  }

  picture {
    position: absolute;
    width: 100vw;
    height: 100dvh;
    top: 0;
    z-index: 0;
    object-fit: cover;
    source {
      object-fit: contain;
    }
  }

  .background {
    position: absolute;
    width: 100vw;
    height: 100dvh;
    top: 0;
    z-index: 0;
    object-fit: cover;

    @include xs {
      display: block;
    }
  }

  .logo {
    z-index: 1;
    position: absolute;
    width: 10vw;
    max-width: 20rem;
    top: 5dvh;

    @include md {
      width: 20vw;
      top: 0;
    }

    @include xs {
      top: 5dvh;
      width: 35vw;
    }
  }

  .bottom {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include md {
      position: unset;
      top: unset;
      transform: unset;
    }

    .button {
      display: grid;
      place-items: center;
      position: relative;
      width: 30vw;
      height: calc(30vw * 66 / 349);

      max-width: 50rem;
      max-height: calc(50rem * 66 / 349);

      cursor: pointer;
      transition: all 0.3s;

      @include md {
        width: 50vw;
        height: calc(50vw * 66 / 349);
      }

      @include xs {
        max-width: unset;
        max-height: unset;
        width: 90vw;
        height: calc(90vw * 66 / 349);
      }

      h2 {
        position: absolute;
        padding-bottom: 1rem;
        color: white;

        &.howToPlay {
          color: black;
        }
      }
    }

    .button2 {
      height: calc(30vw * 60 / 349);

      @include md {
        height: calc(50vw * 60 / 349);
      }

      @include xs {
        height: calc(90vw * 60 / 349);
      }

      h2 {
        font-family: "Teko", sans-serif;
        padding-bottom: 0.2rem;
      }
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
    z-index: 100;
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    height: 58dvh;
    background-color: #c4c4c4;
    border-radius: 2rem;
    border: 0.3rem solid black;

    width: 30vw;

    @include md {
      width: 50vw;
    }

    @include xs {
      width: 80vw;
    }

    .close {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-50%, -40%);
      z-index: 3;
      cursor: pointer;
    }

    .inner {
      position: absolute;
      width: 100%;
      height: calc(100% - 1.4rem);
      top: 0;
      background-color: white;
      border-radius: 1.85rem 1.85rem 2.4rem 2.4rem;
      z-index: 2;
      padding: 3rem 4vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h2 {
        text-align: center;
        color: black;
        font-family: "Teko", sans-serif;
      }

      .paragraphs {
        margin: 3rem 0;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-left: 2rem;

        p {
          color: #000;
          &::first-letter {
            margin-left: -1.4rem;
          }
        }
      }

      .button {
        display: grid;
        place-items: center;
        position: relative;
        width: 100%;
        height: calc(100% * 66 / 349);

        cursor: pointer;

        h3 {
          position: absolute;
          padding-bottom: 0.6rem;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
}
