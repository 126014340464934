@import "../../styles/mixins";

.mainContainer {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  .container {
    background-color: #fff;
    width: 50vw;
    max-width: 50rem;
    height: 65dvh;
    max-height: 55rem;
    border-radius: 3rem;
    overflow: hidden;
    padding: 0.8rem;
    align-self: center;
    @include noUserSelect;
    z-index: 1;

    transition: all 0.3s;

    display: flex;
    flex-direction: column;

    @include sm {
      width: 90vw;
      height: 56dvh;
    }

    .imageContainer {
      aspect-ratio: 16/9;
      border-radius: 2.2rem;
      background-color: #fff;
      overflow: hidden;

      .image {
        object-fit: cover;
        height: 100%;
        width: 100%;
        @include noUserSelect;
      }
    }

    .titleContainer {
      width: 100%;
      flex: 1;
      margin-top: 0.8rem;
      display: grid;
      place-items: center;
      padding: 0 1rem;
      position: relative;

      h2 {
        text-align: center;
        color: #000;
        font-family: "Teko", sans-serif;
      }

      .controls {
        position: absolute;
        top: 0;
        opacity: 0;
        transition: all 0.3s;
        width: 100%;
        background-color: #000;
        padding: 1rem;
        border-radius: 2.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .iconContainer {
          background-color: #fff;
          cursor: pointer;

          transition: all 0.3s;
          border-radius: 2rem;
          padding: 0.7rem;
          display: grid;
          place-items: center;

          &:hover {
            background-color: #c4c4c4;
          }
        }
        .icon {
          width: 2rem;
          height: 2rem;
        }

        .skip {
          background-color: #fff;
          display: inline-block;
          font-size: 2rem;
          padding: 0.6rem 1.6rem;
          border-radius: 1rem;
          cursor: pointer;
          text-transform: uppercase;
          font-weight: 600;

          transition: all 0.3s;

          &:hover {
            background-color: #c4c4c4;
          }
        }
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
    justify-content: space-between;
    width: 50vw;
    max-width: 50rem;
    gap: 2rem;

    @include xs {
      width: 90vw;
    }

    .button {
      width: 50%;
      height: calc(50% * 82 / 169);

      position: relative;
      cursor: pointer;
      display: grid;
      place-items: center;
      overflow: hidden;

      h4 {
        color: #000;
        font-weight: bold;
        position: absolute;
        text-align: center;
        padding: 0 2rem;
        padding-bottom: 1.4rem;
        white-space: pre-line;

        @include noUserSelect;
      }
    }
  }
}
