.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 100dvh;
  margin: 0 25vw;
  padding: 38dvh 0;

  p {
    font-size: 2rem;

    i {
      color: lightgray;
      font-size: 2rem;
    }
  }
}
