@import "../../styles/mixins";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 0 25vw;

  @include xs {
    padding: 0 5vw;
  }

  min-height: 100dvh;
  position: relative;

  .beams {
    position: absolute;
    width: 100vw;
  }

  .logo {
    width: 50vw;
    max-width: 20rem;
    align-self: center;
    transform: translateX(5%);
  }

  .congrats {
    text-align: center;
    font-weight: 400;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .starBox {
    width: 48vw;
    max-width: 20rem;
    position: relative;

    .star {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    text-align: center;
    margin-top: -5vw;
    font-family: "Teko", sans-serif;
    z-index: 300;

    @include xs {
      margin-top: -20vw;
    }

    h1 {
      -webkit-text-stroke: 3px black;
      &:last-of-type {
        font-size: 5.6rem;
        line-height: 4rem;
        -webkit-text-stroke: 3.6px black;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 8rem;
    margin-bottom: 2rem;
    gap: 3rem;

    @include md {
      justify-content: space-between;
      gap: 1.6rem;
    }

    .linkBox {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
      overflow: hidden;
      max-width: 45rem;

      img {
        width: 100%;
        height: 100%;
      }

      a {
        width: 100%;
        position: absolute;
        padding-right: 2rem;

        h3 {
          color: rgb(126, 126, 126);
          padding: 0 2rem;
          padding-bottom: 0.4rem;
          margin-right: 0.3rem;
          font-weight: 600;
          font-family: "Teko", sans-serif;
        }
      }
    }

    .share {
      height: 100%;
      cursor: pointer;
      z-index: 2;
      position: relative;
    }
  }

  .box {
    width: 100%;
    padding-bottom: 1rem;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 2rem;
    border: 0.3rem solid rgba(255, 255, 255, 0.3);
    transition: all 0.3s;

    .inner {
      width: 100%;
      background-color: #fff;
      border-radius: 1.8rem 1.8rem 2.3rem 2.3rem;
      z-index: 2;
      padding: 2.8rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h2 {
        margin-bottom: 2rem;
        text-align: center;
        font-weight: 600;
        color: #000;
      }

      h4 {
        color: #000;
        font-weight: 500;

        &:not(:last-of-type) {
          margin-bottom: 1.2rem;
        }
      }
    }
  }

  .finalButton {
    height: 10rem;
    width: 50%;

    margin: 6rem 0;

    @include xl {
      width: 100%;
    }

    @include xs {
      margin: 4rem 0;
    }

    @include xxs {
      margin: 2rem 0;
    }

    display: grid;
    place-items: center;
    text-align: center;
    position: relative;

    div {
      position: absolute;
      padding-bottom: 1rem;
      h4,
      h3 {
        color: black;
        font-weight: bold;
      }
    }
  }

  .loading {
    display: grid;
    place-items: center;
  }
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  z-index: 100;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101111;
  height: 30dvh;
  background-color: #c4c4c4;
  border-radius: 2rem;
  border: 0.3rem solid black;

  width: 30vw;

  @include md {
    width: 50vw;
  }

  @include xs {
    width: 80vw;
  }

  .close {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -40%);
    z-index: 3;
    cursor: pointer;
  }

  .inner {
    position: absolute;
    width: 100%;
    height: calc(100% - 1.4rem);
    top: 0;
    background-color: white;
    border-radius: 1.85rem 1.85rem 2.4rem 2.4rem;
    z-index: 2;
    padding: 0 4vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    h2 {
      text-align: center;
      color: black;
      font-family: "Teko", sans-serif;
    }

    h4 {
      color: black;
      font-family: "Teko", sans-serif;
    }

    .button {
      display: grid;
      place-items: center;
      position: relative;
      width: 100%;

      cursor: pointer;

      h3 {
        position: absolute;
        padding-bottom: 0.6rem;
        color: white;
        font-weight: bold;
      }
    }
  }
}
